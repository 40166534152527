<template>
    <div class="section index-section" key="speedAccusationSpeed">
        <div class="section-title">
            <h3>Und wie viel km/h waren Sie zu schnell?</h3>
            <p>Bitte prüfen Sie den Bescheid: Wie hoch ist die vorgeworfene Überschreitung der Geschwindigkeit</p>
        </div>
        <div class="form-container">
            <div class="form-card">
            <input type="radio" v-model="user.speedAccusationSpeed" name="radio-4" value="0-15km/h" id="question-6-1">
            <label for="question-6-1" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-speedometer.svg"></span><span>0 - 15 km/h</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.speedAccusationSpeed" name="radio-4" value="16-20km/h" id="question-6-2">
            <label for="question-6-2" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-speedometer.svg"></span><span>16 - 20 km/h</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.speedAccusationSpeed" name="radio-4" value="21-25km/h" id="question-6-3">
            <label for="question-6-3" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-speedometer.svg"></span><span>21 - 25 km/h</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.speedAccusationSpeed" name="radio-4" value=">25km/h" id="question-6-4">
            <label for="question-6-4" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-speedometer.svg"></span><span>über 25 km/h</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.speedAccusationSpeed" name="radio-4" value="unsicher" id="question-6-5">
            <label for="question-6-5" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-speedometer.svg"></span><span>Ich bin mir unsicher</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'SpeedAccusationSpeed',
  computed: mapState({
    user: (state) => state.user,
  }),
  methods: {
    next() {
      this.$emit('next');
    }
  },
  created() {
    this.user.speedAccusationSpeed = '';
  }
}
</script>